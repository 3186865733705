export class DateUpdate {

    constructor(
        public datepicker: string,
        public start: string,
        public stop: string,
        public status: string,
    ) {

    }

}
